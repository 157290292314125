// results bug

import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  ScrollView,
  Platform,
  ActivityIndicator,
  Button, // Add this import
  Pressable,
  Modal,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { database } from "../componenets/firebase";
import {
  collection,
  query,
  onSnapshot,
  doc,
  writeBatch,
  serverTimestamp,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import moment from "moment";

import Icon from "react-native-vector-icons/FontAwesome";

import { Card, Title, Paragraph } from "react-native-paper";

import { Avatar } from "react-native-elements";
import ResultsButton from "./ResultsButton";

import useAvatarUri from "./Avatar";

const Results = ({ navigation, route }) => {
  const { mainGame: mainGameId, TheGameId, gameDate } = route.params;

  const [results, setResults] = useState([]); // State to hold player data
  const [scoreHome, setScoreHome] = useState("");
  const [scoreAway, setScoreAway] = useState("");
  const [scoresSet, setScoresSet] = useState(false);
  const [loading, setLoading] = useState(true);
  const [winPoints, setWinPoints] = useState(null);
  const [losePoints, setLosePoints] = useState(0);

  const [drawPoints, setDrawPoints] = useState(0);
  const [teenGoalPoints, setTeenGoalPoints] = useState(0);
  const [attendancePoints, setAttendancePoints] = useState(0);
  const [pointsEnabled, setPointsEnabled] = useState(false);
  const [sides, setSides] = useState([]);

  const [playerData, setPlayerData] = useState([]);
  const [teamSelection, setTeamSelection] = useState("Home"); // default to "home"
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isRandomizeModalVisible, setRandomizeModalVisible] = useState(false);
  const [gameSettings, setGameSettings] = useState(null);
  const [isInvalidPlayersModalVisible, setInvalidPlayersModalVisible] = useState(false);
  const [invalidPlayers, setInvalidPlayers] = useState([]);

  const clearTeamAssignments = () => {
    const updatedPlayerData = playerData.map((player) => ({
      ...player,
      team: "", // or null, depending on your preference
    }));

    setPlayerData(updatedPlayerData);
    // Optionally save the updated data to Firestore if needed
    savePlayerDataToFirestore(mainGameId, TheGameId, updatedPlayerData);
  };

  const randomizeAndSaveTeams = async () => {
    // Separate players into manually assigned and unassigned
    const manuallyAssigned = playerData.filter((player) => player.team);
    const unassignedPlayers = playerData.filter((player) => !player.team);

    // Shuffle and distribute the unassigned players
    const shuffledUnassigned = unassignedPlayers.sort(() => 0.5 - Math.random());
    const totalPlayers = playerData.length;
    const halfTeamSize = Math.ceil(totalPlayers / 2);
    const updatedUnassigned = shuffledUnassigned.map((player, index) => {
      // Distribute alternately, considering the number of manually assigned players
      const team =
        index + manuallyAssigned.filter((p) => p.team === "Home").length < halfTeamSize
          ? "Home"
          : "Away";
      return { ...player, team: team };
    });

    // Combine the manually assigned and newly assigned players
    const updatedPlayerData = [...manuallyAssigned, ...updatedUnassigned];

    setPlayerData(updatedPlayerData); // Update the state
    await savePlayerDataToFirestore(mainGameId, TheGameId, updatedPlayerData); // Save to Firestore
  };

  const savePlayerDataToFirestore = async (mainGameId, TheGameId, playerData) => {
    const batch = writeBatch(database);

    playerData.forEach((player) => {
      if (player && player.id) {
        player.fouls = player.fouls || 0; // Default to 0 if fouls is not set.

        // Ensure the team field is not undefined, default to an empty string
        const team = player.team || "";

        const {
          teenGoalPoints,
          drawPoints,
          winPoints,
          losePoints,
          attendancePoints,
          totalPoints,
          result,
          fouls,
        } = calculatePoints(scoreHome, scoreAway, team, player.fouls);

        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.id
        );

        batch.set(
          playerRef,
          {
            scoreHome,
            scoreAway,
            team: team, // Use the team variable that ensures it's not undefined
            TeenGoalPoints: teenGoalPoints,
            drawPoints,
            winPoints,
            losePoints,
            AttendancePoints: attendancePoints,
            totalPoints: totalPoints,
            Result: result,
            fouls: player.fouls,
          },
          { merge: true }
        );
      }
    });

    try {
      await batch.commit();
      console.log("Team data saved successfully");
    } catch (error) {
      console.error("Error saving team data: ", error);
    }
  };

  const countTeamPlayers = () => {
    const teamCounts = playerData.reduce(
      (counts, player) => {
        if (player.team === "Home") {
          counts.home += 1;
        } else if (player.team === "Away") {
          counts.away += 1;
        }
        return counts;
      },
      { home: 0, away: 0 }
    );

    return teamCounts;
  };

  const teamCounts = countTeamPlayers();

  const filteredResults = playerData.filter((player) => player.team === teamSelection);

  //Game settings / poitns and stuff..
  useEffect(() => {
    const fetchGameSettings = async () => {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);

      const gameSettingsSnap = await getDoc(gameSettingsRef);

      if (gameSettingsSnap.exists()) {
        setGameSettings(gameSettingsSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchGameSettings();
  }, [mainGameId, TheGameId]); // Dependencies for useEffect

  function TeamButton({ isSelected, title, onPress, style }) {
    return (
      <Pressable
        onPress={onPress}
        style={({ pressed }) => [
          styles.button,
          isSelected ? styles.buttonSelected : styles.buttonUnselected,
          pressed ? styles.buttonPressed : null,
          style, // Added this line
        ]}
      >
        <Text style={isSelected ? styles.textSelected : styles.textUnselected}>
          {title}
        </Text>
      </Pressable>
    );
  }

  const calculatePoints = (scoreHome, scoreAway, playerTeam, playerFouls = 0) => {
    let drawPointsConfig,
      winPointsConfig,
      losePointsConfig,
      attendancePointsConfig,
      teamGoalPointsConfig;

    // Check if pointsEnabled is true in gameSettings
    if (gameSettings && gameSettings.pointsEnabled) {
      drawPointsConfig =
        gameSettings.drawPoints !== null && gameSettings.drawPoints !== undefined
          ? gameSettings.drawPoints
          : 0;
      winPointsConfig =
        gameSettings.winPoints !== null && gameSettings.winPoints !== undefined
          ? gameSettings.winPoints
          : 1;
      losePointsConfig =
        gameSettings.losePoints !== null && gameSettings.losePoints !== undefined
          ? gameSettings.losePoints
          : 13;
      attendancePointsConfig =
        gameSettings.attendancePoints !== null &&
        gameSettings.attendancePoints !== undefined
          ? gameSettings.attendancePoints
          : 0;
      teamGoalPointsConfig =
        gameSettings.TeenGoalPoints !== null && gameSettings.TeenGoalPoints !== undefined
          ? gameSettings.TeenGoalPoints
          : 0;
    }

    let drawPoints = 0,
      winPoints = 0,
      losePoints = 0,
      attendancePoints = 0,
      teenGoalPoints = 0,
      result = "";

    attendancePoints = scoreHome > 0 || scoreAway > 0 ? attendancePointsConfig : 0;

    if (scoreHome === scoreAway) {
      drawPoints = drawPointsConfig;
      result = "draw";
    } else if (
      (scoreHome > scoreAway && playerTeam === "Home") ||
      (scoreAway > scoreHome && playerTeam === "Away")
    ) {
      winPoints = winPointsConfig;
      result = "win";
    } else {
      losePoints = losePointsConfig;
      result = "lost";
    }

    if (
      (playerTeam === "Home" && scoreHome >= 10) ||
      (playerTeam === "Away" && scoreAway >= 10)
    ) {
      teenGoalPoints = teamGoalPointsConfig;
    }

    const totalPoints =
      drawPoints +
      winPoints +
      losePoints +
      attendancePoints +
      teenGoalPoints +
      playerFouls;

    return {
      drawPoints,
      winPoints,
      losePoints,
      attendancePoints,
      teenGoalPoints,
      totalPoints,
      result,
      fouls: playerFouls,
    };
  };

  const handleBatchSave = async (scoreHome, scoreAway) => {
    try {
      scoreHome = parseInt(scoreHome, 10);
      scoreAway = parseInt(scoreAway, 10);

      if (isNaN(scoreHome) || isNaN(scoreAway)) {
        console.error("Invalid score values:", scoreHome, scoreAway);
        return;
      }

      if (!Array.isArray(playerData)) {
        console.error("playerData is not an array:", playerData);
        return;
      }

      const batch = writeBatch(database);
      const invalidPlayersList = [];

      playerData.forEach((player) => {
        if (player && player.id) {
          player.fouls = player.fouls || 0;

          if (!player.team) {
            console.log(`Player with undefined team detected: ${player.Name}`);
            console.log(
              `Assigning only attendance points for player: ${player.Name}`
            );
          }

          const {
            teenGoalPoints,
            drawPoints,
            winPoints,
            losePoints,
            attendancePoints,
            totalPoints,
            result,
            fouls,
          } = calculatePoints(scoreHome, scoreAway, player.team, player.fouls);

          const playerRef = doc(
            database,
            "MainGame",
            mainGameId,
            "TheGame",
            TheGameId,
            "Players",
            player.id
          );

          batch.set(
            playerRef,
            {
              scoreHome,
              scoreAway,
              team: player.team || "", // Default to an empty string if team is undefined
              TeenGoalPoints: teenGoalPoints,
              drawPoints,
              winPoints,
              losePoints,
              AttendancePoints: attendancePoints,
              totalPoints: totalPoints,
              Result: result,
              fouls: player.fouls,
            },
            { merge: true }
          );
        } else {
          invalidPlayersList.push(player.Name);
          console.warn("Invalid player object or team is undefined:", player);
        }
      });

      if (invalidPlayersList.length > 0) {
        setInvalidPlayers(invalidPlayersList);
        setInvalidPlayersModalVisible(true);
        return;
      }

      const gameResultRef = doc(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId
      );

      batch.set(
        gameResultRef,
        {
          scoreHome,
          scoreAway,
        },
        { merge: true }
      );

      await batch.commit();

      console.log("Batch update and result save successful!");
    } catch (error) {
      console.error("Failed to commit batch update:", error);
    }
  };

  //useEffect that fetches ONLY scores
  useEffect(() => {
    const fetchScores = async () => {
      const gameRef = doc(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId
      );
      const gameDoc = await getDoc(gameRef);

      if (gameDoc.exists()) {
        setScoreHome(gameDoc.data().scoreHome || "");
        setScoreAway(gameDoc.data().scoreAway || "");
      }
    };

    fetchScores();
  }, [mainGameId, TheGameId]);

  // useEffect that fetches player details
  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players"
        ),
        where("value", "==", "Going"),
        orderBy("Name")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const playerDetails = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });

        setPlayerData(playerDetails); // Setting the complete player data to state
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, [mainGameId, TheGameId]);

  function RenderItem({ item, setPlayerData }) {
    const [teamSelection, setTeamSelection] = useState(item.team || null);
    const [fouls, setFouls] = useState(item.fouls || 0); // New state for fouls

    const handleTeamSelection = useCallback(
      async (team) => {
        const newTeamSelection = teamSelection === team ? null : team;

        setTeamSelection(newTeamSelection);

        setPlayerData((prevData) =>
          prevData.map((player) =>
            player.id === item.id
              ? { ...player, team: newTeamSelection }
              : player
          )
        );

        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          item.id
        );

        try {
          await setDoc(playerRef, { team: newTeamSelection }, { merge: true });
          console.log("Player team selection saved successfully!");
        } catch (error) {
          console.error("Error saving player team selection: ", error);
        }
      },
      [item, setPlayerData, teamSelection]
    );

    const increaseFouls = () => {
      const newFouls = fouls + 1;
      setFouls(newFouls);

      setPlayerData((prevData) =>
        prevData.map((player) =>
          player.id === item.id ? { ...player, fouls: newFouls } : player
        )
      );
    };

    const decreaseFouls = () => {
      const newFouls = fouls - 1;
      setFouls(newFouls);

      setPlayerData((prevData) =>
        prevData.map((player) =>
          player.id === item.id ? { ...player, fouls: newFouls } : player
        )
      );
    };

    return (
      <View style={styles.playerRow}>
        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexGrow: 1 }}>
          <Text style={styles.playerName}>
            {item.Name.length > 20 ? `${item.Name.substring(0, 20)}...` : item.Name}
          </Text>
          <Text style={styles.playerEmail}>{item.email}</Text>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TeamButton
            title="Home"
            onPress={() => handleTeamSelection("Home")}
            isSelected={teamSelection === "Home"}
            style={{ marginRight: 10 }} // Added this line
          />
          <TeamButton
            title="Away"
            onPress={() => handleTeamSelection("Away")}
            isSelected={teamSelection === "Away"}
          />

          {/* New code for foul management */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 30,
            }}
          >
            <Text style={{ marginRight: 10 }}>Penalty</Text>

            <Button title="-" onPress={decreaseFouls} />
            <Text
              style={{
                width: 30,
                textAlign: "center",
                marginLeft: 15,
                marginRight: 15,
              }}
            >
              {fouls}
            </Text>
            <Button title="+" onPress={increaseFouls} />
          </View>
          <Text style={{ marginRight: 10 }}>Bonus</Text>
        </View>
      </View>
    );
  }

  const dataWithHeader = [{ isHeader: true }, ...playerData.sort((a, b) => a.Name.localeCompare(b.Name))];

  const renderItem = ({ item }) => {
    if (item.isHeader) {
      return (
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Name</Text>
          <Text style={styles.headerText}>Team</Text>
          <Text style={styles.headerText}>Points</Text>
        </View>
      );
    } else {
      return <RenderItem item={item} setPlayerData={setPlayerData} />;
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.header}>
        <View style={styles.topButtonsContainer}>
          <Pressable style={styles.topButton} onPress={randomizeAndSaveTeams}>
            <Text style={styles.topButtonText}>Randomize Teams</Text>
          </Pressable>
          <Pressable style={styles.topButton} onPress={clearTeamAssignments}>
            <Text style={styles.topButtonText}>Clear Teams</Text>
          </Pressable>
          {gameSettings ? (
            <Pressable
              onPress={() => setModalVisible(true)}
              style={
                gameSettings.pointsEnabled
                  ? [styles.topButton, styles.pointsEnabled]
                  : [styles.topButton, styles.pointsDisabled]
              }
            >
              <Text style={styles.topButtonText}>
                {gameSettings.pointsEnabled ? "Points Enabled" : "Points Disabled"}
              </Text>
            </Pressable>
          ) : (
            <ActivityIndicator size="small" color="#0000ff" />
          )}
        </View>
        <Text style={styles.headerText}>
          Name: {gameSettings?.name ?? "Loading..."} | Date:{" "}
          {gameDate ? moment(gameDate).format("DD MMMM YYYY") : "Loading date..."}
        </Text>

        <Pressable
          style={styles.gameDashboardButton}
          onPress={async () => {
            const playersSnapshot = await getDocs(
              collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players")
            );

            const players = playersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const batch = writeBatch(database);
            players.forEach((player) => {
              const playerRef = doc(
                database,
                "MainGame",
                mainGameId,
                "TheGame",
                TheGameId,
                "Players",
                player.id
              );
              batch.set(
                playerRef,
                {
                  TeenGoalPoints: null,
                  drawPoints: null,
                  fouls: null,
                  losePoints: null,
                  score: null,
                  scoreAway: null,
                  scoreHome: null,
                  totalPoints: null,
                  winPoints: null,
                },
                { merge: true }
              );
            });
            await batch.commit();

            await handleBatchSave(scoreHome, scoreAway);

            const invalidPlayersList = playerData
              .filter((player) => !player.team && player.value === "Going")
              .map((player) => player.Name);

            if (invalidPlayersList.length > 0) {
              setInvalidPlayers(invalidPlayersList);
              setInvalidPlayersModalVisible(true);
            } else {
              navigation.navigate("GameDashboard", {
                gameDate,
                mainGameId,
                TheGameId,
              });
            }
          }}
        >
          <Text style={styles.gameDashboardButtonText}>Continue to Leaderboard</Text>
        </Pressable>
      </View>

      {/* Combined Home vs Away text and score inputs in one container */}
      <View style={styles.scoreContainer}>
        <View style={styles.teamLabels}>
          <Text style={styles.homeText}>Home</Text>
          <Text style={styles.awayText}>Away</Text>
        </View>
        <View style={styles.scoreInputs}>
          <TextInput
            style={styles.textInput}
            placeholder="Enter - Score Home"
            onChangeText={(text) => {
              setScoreHome(text);
              setScoresSet(true);
            }}
            value={scoreHome.toString()}
            selectTextOnFocus={true}
            keyboardType="number-pad"
            returnKeyType="done"
          />
          <TextInput
            style={styles.textInput}
            placeholder="Enter - Score Away"
            onChangeText={(text) => {
              setScoreAway(text);
              setScoresSet(true);
            }}
            value={scoreAway.toString()}
            selectTextOnFocus={true}
            keyboardType="number-pad"
            returnKeyType="done"
          />
        </View>
      </View>

      <View style={{ flexDirection: "row", marginVertical: 10, marginLeft: 15, alignItems: "center" }}>
        <Text>Home Team Players: {teamCounts.home} </Text>
        <Text style={{ marginLeft: 20 }}>Away Team Players: {teamCounts.away}</Text>
      </View>

      {isInvalidPlayersModalVisible && invalidPlayers.length > 0 && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={isInvalidPlayersModalVisible}
          onRequestClose={() => setInvalidPlayersModalVisible(false)}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.invalidPlayersTitle}>Players with Undefined Teams:</Text>
              <Text> Note: Players will receive attendance points.</Text>

              {invalidPlayers.map((name, index) => (
                <Text key={index} style={styles.invalidPlayerName}>
                  {name}
                </Text>
              ))}
              <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                <Pressable
                  style={[styles.modalButton, styles.buttonClose]}
                  onPress={() => setInvalidPlayersModalVisible(false)}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Pressable>
                <Pressable
                  style={[styles.modalButton, styles.buttonContinue]}
                  onPress={() => {
                    setInvalidPlayersModalVisible(false);
                    navigation.navigate("GameDashboard", {
                      gameDate,
                      mainGameId,
                      TheGameId,
                    });
                  }}
                >
                  <Text style={styles.textStyle}>Continue</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>
      )}

      <View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          onRequestClose={() => setModalVisible(false)}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {gameSettings ? (
                <>
                  <Text>Draw Points: {gameSettings.drawPoints}</Text>
                  <Text>Win Points: {gameSettings.winPoints}</Text>
                  <Text>Lose Points: {gameSettings.losePoints}</Text>
                  <Text>Attendance Points: {gameSettings.attendancePoints}</Text>
                  <Text>Ten Goal Points: {gameSettings.TeenGoalPoints}</Text>
                  <Text>Absent Points: {gameSettings.absentPoints}</Text>
                </>
              ) : (
                <Text>Loading points...</Text>
              )}

              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.textStyle}>Close</Text>
              </Pressable>
            </View>
          </View>
        </Modal>

        <Modal
          animationType="slide"
          transparent={true}
          visible={isRandomizeModalVisible}
          onRequestClose={() => setRandomizeModalVisible(false)}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text>"Randomize Teams" assigns players randomly to 'Home' or 'Away'.</Text>
              <Text>Players who have already been assigned to a team will retain their assignment.</Text>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setRandomizeModalVisible(false)}
              >
                <Text style={styles.textStyle}>Close</Text>
              </Pressable>
            </View>
          </View>
        </Modal>
      </View>

      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <FlatList
          data={dataWithHeader}
          renderItem={renderItem}
          keyExtractor={(item, index) => (item.isHeader ? "header" : item.id)}
          contentContainerStyle={styles.flatListContent} // Added this line
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default Results;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5FCFF",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginVertical: 15,
    color: "#333",
    fontWeight: "bold",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    backgroundColor: "#fafafa",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },

  buttonSelected: {
    backgroundColor: "#4C7BEE",
  },
  buttonUnselected: {
    backgroundColor: "#ddd",
  },
  buttonPressed: {
    backgroundColor: "#395FCC",
  },

  pointsEnabled: {
    padding: 5,
    backgroundColor: "#e8ffe8",
  },
  pointsDisabled: {
    padding: 10,
    backgroundColor: "#ffe8e8",
  },
  homeVsAway: {
    alignItems: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },

  button: {
    borderRadius: 15,
    padding: 10,
    elevation: 2,
    width: 90,
    alignItems: "center",
  },
  buttonText: {
    fontSize: Platform.OS === "ios" ? 13 : 14,
    fontWeight: "600",
  },
  textSelected: {
    color: "white",
    fontSize: Platform.OS === "ios" ? 14 : 14,
    fontWeight: "600",
  },
  textUnselected: {
    color: "#666",
    fontSize: Platform.OS === "ios" ? 13 : 14,
    fontWeight: "600",
  },

  continueButtonText: {
    fontSize: 16,
  },

  gameDashboardButton: {
    backgroundColor: "#4C7BEE",
    paddingVertical: 5,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
  gameDashboardButtonText: {
    color: "#FFF",
    fontSize: 18,
    fontWeight: "bold",
  },

  loading: {
    backgroundColor: "lightyellow",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginVertical: 5,
  },

  nameDateContainer: {
    maxHeight: 150,
    justifyContent: "center",
    alignItems: "center",
  },

  statusText: {
    fontSize: 10,
    fontWeight: "bold",
  },

  boldText: {
    fontWeight: "bold",
  },

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },

  infoButton: {
    padding: 10,
    backgroundColor: "#2196F3",
    borderRadius: 5,
  },
  infoButtonText: {
    color: "#fff",
  },

  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },

  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontSize: 18,
    color: "#333",
    fontWeight: "bold",
  },

  button: {
    borderRadius: 15,
    padding: Platform.OS === "ios" ? 5 : 10,
    elevation: 2,
    width: Platform.OS === "ios" ? 50 : 90,
    alignItems: "center",
  },

  buttonClose: {
    backgroundColor: "#f44336",
  },

  settingsContainer: {
    padding: 15,
    borderRadius: 10,
    backgroundColor: "#f7f7f7",
    marginVertical: 15,
  },

  settingText: {
    fontSize: 16,
    color: "#333",
    marginBottom: 10,
  },

  randomizeButton: {
    backgroundColor: "#4CAF50",
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
    marginHorizontal: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
  },

  randomizeButtonText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },

  clearTeamsButton: {
    backgroundColor: "#F44336",
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
    marginHorizontal: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
  },

  clearTeamsButtonText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },

  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: "#eee",
  },

  buttonContinue: {
    backgroundColor: "#4CAF50",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },

  header: {
    padding: 10,
    backgroundColor: "#eee",
  },
  topButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  topButton: {
    padding: 10,
    backgroundColor: "#e8e8e8",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5,
  },
  topButtonText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  headerText: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
  },
  scoreInputs: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 20,
  },
  textInput: {
    flex: 1,
    marginHorizontal: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    textAlign: "center",
  },
  playerRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  playerName: {
    flex: 3,
    fontSize: 14,
  },
  teamButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 2,
  },
  teamButton: {
    flex: 1,
    textAlign: "center",
    backgroundColor: "#ddd",
    borderRadius: 8,
    marginHorizontal: 5,
    padding: 5,
  },
  teamSelected: {
    backgroundColor: "#4C7BEE",
    color: "white",
  },

  scoreContainer: {
    alignItems: "center",
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  teamLabels: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  homeText: {
    flex: 1,
    textAlign: "center",
    color: "blue",
    fontSize: 20,
  },
  awayText: {
    flex: 1,
    textAlign: "center",
    color: "red",
    fontSize: 20,
  },
  scoreInputs: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textInput: {
    flex: 1,
    marginHorizontal: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    textAlign: "center",
    fontSize: 16,
  },

  flatListContent: {
    paddingVertical: 10,
  },

  invalidPlayersTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 15,
  },
  invalidPlayerName: {
    fontSize: 16,
    marginVertical: 5,
  },

  modalButton: {
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    minWidth: 100,
    marginHorizontal: 10,
  },

  playerEmail: {
    fontSize: 12,
    color: "#666",
  },
});
