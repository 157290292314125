import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Button,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  Linking,
  ScrollView,
} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Ionicons from "react-native-vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { Avatar } from "react-native-elements";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  getFirestore,
  runTransaction,
  query,
  where,
} from "firebase/firestore";
import { database, auth } from "../componenets/firebase";
import { getAuth } from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import * as ImagePicker from "expo-image-picker";

import { MaterialIcons } from "@expo/vector-icons";

//import { getExpoPushToken } from '../navigation/Notification';

const ProfileTestStack = createNativeStackNavigator();

//const [players, setPlayers] = useState([]);

const Profile = () => (
  <ProfileTestStack.Navigator
    screenOptions={{
      headerShown: true,
    }}
  >
    <ProfileTestStack.Screen
      name="ProfileTestScreen"
      component={ProfileTestScreen}
      options={({ navigation }) => ({
        headerTitle: () => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => navigation.goBack()}
              style={{ marginRight: 10 }}
            >
              {/* <Ionicons name="arrow-back" size={24} color="white" /> */}
              <MaterialIcons name="arrow-back" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text style={{ color: "white", fontWeight: "bold", fontSize: 18  }}>
                Go back
              </Text>
            </TouchableOpacity>
          </View>
        ),

        headerStyle: {
          backgroundColor: "#4361ee",
        },
        headerTintColor: "white",
        headerTitleStyle: {
          fontWeight: "bold",
          color: "white",
        },
        headerRight: () => (
          <View style={{ marginRight: 10 }}>
            <Avatar
              rounded
              size="medium"
              source={{
                uri: "https://i.pravatar.cc/300",
              }}
              onPress={() => navigation.openDrawer()}
              activeOpacity={0.1}
            />
            <TouchableOpacity onPress={() => navigation.openDrawer()}>
              <Text style={{ color: "red" }}> User! </Text>
            </TouchableOpacity>
          </View>
        ),
      })}
    />
  </ProfileTestStack.Navigator>
);

const ProfileTestScreen = () => {
  const navigation = useNavigation();
  const [userName, setUserName] = useState("");
  const [originalUserName, setOriginalUserName] = useState(""); // New state to store original userName
  const [email, setEmail] = useState("");
  const [originalEmail, setOriginalEmail] = useState(""); // New state to store original email
  const [editing, setEditing] = useState(false);
  const [avatarUriURL, setAvatarUriURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const storage = getStorage(); // Initialize the storage instance from Firebase

  // Add a state to act as a trigger for refetching user data
  const [dataFetchTrigger, setDataFetchTrigger] = useState(false);

  useEffect(() => {
    // Listen for when the screen is focused to fetch user data
    const unsubscribe = navigation.addListener("focus", fetchUserData);
    return unsubscribe;
  }, [navigation]);
  // }, [navigation, fetchUserData]);

  // Effect to update navigation options based on userName and avatarUriURL state changes
  useEffect(() => {
    const headerRightComponent = () => (
      <View style={{ alignItems: "center", marginRight: 28 }}>
        <Avatar
          rounded
          size="medium"
          source={{ uri: avatarUriURL || "https://i.pravatar.cc/300" }}
          // onPress={() => console.log('Avatar pressed')}
          activeOpacity={0.7}
        />
        {/* <TouchableOpacity onPress={() => navigation.openDrawer()}> */}
        <Text style={{ color: "white", fontSize: 14, marginTop: 0 }}>
          {userName}
        </Text>
        {/* </TouchableOpacity> */}
      </View>
    );

    navigation.setOptions({
      headerRight: headerRightComponent,
    });
  }, [userName, avatarUriURL, navigation]);

  const fetchUserData = useCallback(async () => {
    const authInstance = getAuth();
    const firestoreInstance = getFirestore();
    const currentUser = authInstance.currentUser;

    if (currentUser) {
      const userDocRef = doc(firestoreInstance, "users", currentUser.uid);
      try {
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserName(userData.name);
          setEmail(userData.email); // Fetch and set the current email

          setAvatarUriURL(userData.avatarUri || "https://i.pravatar.cc/300");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  }, []);

 

  const handleEditProfile = async () => {
    setIsLoading(true);
    if (editing) {
      // User is currently in edit mode and wants to save changes
      const userId = auth.currentUser.uid;
      await updateUserInfo(userId);
      setEditing(false);
    } else {
      // User wants to enter edit mode, store original values
      setOriginalUserName(userName);
      setOriginalEmail(email);
      setEditing(true);
    }
    setIsLoading(false);
  };

  const handleCancelEdit = () => {
    // Revert to original values and exit edit mode
    setUserName(originalUserName);
    setEmail(originalEmail);
    setEditing(false);
  };

  const handleUploadImage = async () => {
    // setIsLoading(true);
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!permissionResult.granted) {
      alert("Permission to access the photo library is required!");
      setIsLoading(false);
      return;
    }

    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 1,
    });

   // console.log(pickerResult);

    if (!pickerResult.canceled) {
      try {
        const downloadUrl = await uploadToFirebase(pickerResult.assets[0].uri);
     //   console.log("Image uploaded successfully:", downloadUrl);
        setAvatarUriURL(downloadUrl); // Update the avatar URL
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        //  setIsLoading(false);
      }
    } else {
    //  console.log("Image picker was cancelled.");
      // setIsLoading(false);
    }
  };

  const uploadToFirebase = async (uri, onProgress = () => {}) => {
    // Default to a no-op function if onProgress isn't provided
    const response = await fetch(uri);
    const blob = await response.blob();
    const storageRef = ref(
      getStorage(),
      `userProfile/${auth.currentUser.uid}/profile-picture`
    );

    const uploadTask = uploadBytesResumable(storageRef, blob);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress); // This will safely call onProgress, even if it's a no-op
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadUrl);
        }
      );
    });
  };

  const updateUserInfo = async (userId) => {
    let updateData = {
      name: userName, // Assuming this is the updated name from the state
      Name: userName, // Duplicate to ensure compatibility
      email: email, // Assuming this is the updated email from the state
    };

    if (avatarUriURL) {
      updateData.avatarUri = avatarUriURL; // Assuming this comes from the state after image upload
    }

    // Update the main user document in the users collection
    const userRef = doc(database, "users", userId);
    await updateDoc(userRef, updateData);
   // console.log(`Successfully updated main user record for user ${userId}`);

    // Get a reference to the MainGame collection
    const mainGameRef = collection(database, "MainGame");

    // Fetch all documents in the MainGame collection
    const mainGameSnapshot = await getDocs(mainGameRef);

    for (const mainGameDoc of mainGameSnapshot.docs) {
      let gameData = mainGameDoc.data();
      let memberUpdated = false; // Flag to track if any member's info was updated

      // Iterate over the memberOfGame array to find and update the user by their uid
      const updatedMemberOfGame = gameData.memberOfGame.map((member) => {
        if (member.uid === userId) {
          // Use 'uid' to match the user
          memberUpdated = true; // Indicate that we've updated a member's info
          return {
            ...member,
            name: userName,
            Name: userName,
            email: email,
            ...(avatarUriURL && { avatarUri: avatarUriURL }),
          };
        }
        return member;
      });

      if (memberUpdated) {
        // If the user's info was updated in the memberOfGame array, apply the changes to the Firestore document
        await updateDoc(doc(database, "MainGame", mainGameDoc.id), {
          memberOfGame: updatedMemberOfGame,
        });
        // console.log( `Updated name for user ${userId} in memberOfGame of MainGame document: ${mainGameDoc.id}` );
      }

      // Additionally, iterate over TheGame subcollections if there are specific player details to update
      const theGameRef = collection(mainGameDoc.ref, "TheGame");
      const theGameSnapshot = await getDocs(theGameRef);

      for (const theGameDoc of theGameSnapshot.docs) {
        // Here you would update specific details within each game. For example, if each game has a Players subcollection:
        const playerDocRef = doc(theGameDoc.ref, "Players", userId);
        const playerDocSnapshot = await getDoc(playerDocRef);

        if (playerDocSnapshot.exists()) {
          // Update the player document within TheGame subcollection
          await updateDoc(playerDocRef, updateData);
         // console.log( `Updated player ${userId} in ${theGameDoc.id} within MainGame ${mainGameDoc.id}` );
        }
      }
    }
  };

   

  const handleEditUserProfile = async (editedData) => {
    const firestore = getFirestore();
    const userId = auth.currentUser.uid; // Assuming this is how you get the current user's ID
    //const userRef = doc(firestore, "users", userId);

    const docRef = doc(firestore, "MainGame", "wyNT5gAHx2zo9al65zEp");

    try {
      await runTransaction(firestore, async (transaction) => {
        const docSnapshot = await transaction.get(docRef);
        if (!docSnapshot.exists()) {
          throw new Error("Document does not exist!");
        }

        // console.log("All players:", players);

        const userData = docSnapshot.data();
        // console.log("Current user data:", userData);

        // Check if the user is verified and prevent editing Name and Email
        // Assuming you have similar logic/fields for users as you have for players
        // if (userData.verified && ('name' in editedData || 'email' in editedData)) {
        //   console.error("Cannot edit Name or Email of a verified user:", editedData);
        //   return; // Exit the function to prevent updating the user's details
        // }

        // Construct the updated user data
        const updatedUserData = {
          ...userData,
          ...editedData,
        };

       // console.log("Updated user data:", updatedUserData);
         transaction.update(userRef, updatedUserData); }  );

   //   console.log("User profile updated successfully");
    } catch (e) {
   //   console.error("Transaction failed: ", e);
    }
  };

   

  const updateUserInfoInMainGame1 = useCallback(
    async () => {
      const firestore = getFirestore();
      const auth = getAuth();
      const currentUserUid = auth.currentUser?.uid;
      const oldEmail = auth.currentUser?.email;

      if (!currentUserUid || !oldEmail) {
       // console.log("User UID or email not found");
        return;
      }

      const mainGameRef = collection(firestore, "MainGame");
      const querySnapshot = await getDocs(mainGameRef);

      for (const docSnapshot of querySnapshot.docs) {
        const gameData = docSnapshot.data();
        const docRef = doc(firestore, "MainGame", docSnapshot.id);

        // Check if any member of the game matches the old email
        const memberWithEmailExists = gameData.memberOfGame.some(
          (member) => member.email === oldEmail
        );

        if (memberWithEmailExists) {
         // console.log(`Found email in MainGame document ${docSnapshot.id}:`, oldEmail );

          // Update 'Players' Subcollection
          const theGameRef = collection(
            firestore,
            "MainGame",
            docSnapshot.id,
            "TheGame"
          );
          const theGameSnapshot = await getDocs(theGameRef);

          for (const theGameDoc of theGameSnapshot.docs) {
            const playersRef = collection(
              firestore,
              "MainGame",
              docSnapshot.id,
              "TheGame",
              theGameDoc.id,
              "Players"
            );
            const playersSnapshot = await getDocs(playersRef);

            for (const playerDoc of playersSnapshot.docs) {
              const playerData = playerDoc.data();
              if (playerData.email === oldEmail) {
                const playerDocRef = doc(
                  firestore,
                  "MainGame",
                  docSnapshot.id,
                  "TheGame",
                  theGameDoc.id,
                  "Players",
                  playerDoc.id
                );
                await updateDoc(playerDocRef, {
                  id: currentUserUid,
                  Name: userName,
                  email: email,
                });
              }
            }
          }

          // Update 'memberOfGame'
          const updatedMembers = gameData.memberOfGame.map((member) => {
            if (member.email === oldEmail) {
              return {
                ...member,
                uid: currentUserUid,
                name: userName,
                email: email,
                role: "verified",
              };
            }
            return member;
          });

          await updateDoc(docRef, { memberOfGame: updatedMembers });
        }
      }

      // console.log( `Completed updating user information for ${currentUserUid} in MainGame collection`  );
    },
    [userName, email] // Dependencies
  );

   

  return (
    <ScrollView contentContainerStyle={styles.container}>

 
      <Text style={styles.title}>My Profile</Text>
      {isLoading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#0000ff" />
          <Text style={styles.loadingText}>
            Updating, this may take a minute...
          </Text>
        </View>
      ) : (
        <>
          {editing ? (
            <>
              <Text style={styles.text}>User Name:</Text>
              <TextInput
                value={userName}
                onChangeText={setUserName}
                style={styles.input}
                placeholder="User Name"
              />
              <TouchableOpacity
                onPress={handleUploadImage}
                style={[styles.button, styles.uploadButton]}
              >
                <Text style={styles.buttonText}>Upload Profile Image</Text>
              </TouchableOpacity>
              <View style={styles.buttonGroup}>
                <Pressable
                  onPress={handleEditProfile}
                  style={({ pressed }) => [
                    styles.button,
                    styles.saveButton,
                    { marginRight: 10 }, // Adds spacing between the buttons
                    { opacity: pressed ? 0.5 : 1 }, // Dim the button when pressed
                  ]}
                >
                  <Text style={styles.buttonText}>Save</Text>
                </Pressable>
                <Pressable
                  onPress={handleCancelEdit}
                  style={({ pressed }) => [
                    styles.button,
                    styles.cancelButton,
                    { opacity: pressed ? 0.5 : 1 }, // Dim the button when pressed
                  ]}
                >
                  <Text style={styles.buttonText}>Cancel</Text>
                </Pressable>
              </View>
            </>
          ) : (
            <>
              <Text style={styles.text}>{userName}</Text>
              <Text style={styles.text}>{email}</Text>
              <Avatar
                rounded
                size="xlarge"
                source={{ uri: avatarUriURL || "https://i.pravatar.cc/300" }}
                //onPress={() => navigation.openDrawer()}
                activeOpacity={0.7}
                containerStyle={styles.avatar}
              />
              <Pressable
                onPress={handleEditProfile}
                style={({ pressed }) => [
                  styles.button,
                  { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
                ]}
              >
                <Text style={styles.buttonText}>Edit Profile</Text>
              </Pressable>

              <Pressable
              onPress={() => navigation.navigate('AccountSettings')}
                style={({ pressed }) => [
                  styles.button,
                  { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
                ]}
              >
                <Text style={styles.buttonText}>Manage Account</Text>
              </Pressable>

              <Pressable
  onPress={() => Linking.openURL('https://www.privacypolicies.com/live/5bd23615-8735-4c49-9ce2-5864543b187c')}
  style={({ pressed }) => [
    styles.button,
    { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
  ]}
>
  <Text style={styles.buttonText}>Privacy Policy</Text>
</Pressable>


            </>
          )}
        </>
      )}
      </ScrollView>
  );
};
  
  
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff", // Use a clean white background
  },
  title: {
    fontSize: 26,
    marginBottom: 20,
    fontWeight: "bold",
    color: "#333333", // Dark grey for better readability
  },
  text: {
    fontSize: 18,
    color: "#4a4a4a", // Soft black/very dark grey
    marginBottom: 12,
  },
  input: {
    borderColor: "#cccccc", // Light grey border
    borderWidth: 1,
    borderRadius: 5,
    padding: 12,
    width: "90%", // Slightly wider input
    marginBottom: 15,
    fontSize: 16, // Larger font size for input
  },
  button: {
    marginTop: 12,
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 5,
    backgroundColor: "#4361ee", // Consistent blue theme for buttons
    shadowOpacity: 0.3,
    shadowRadius: 4,
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    elevation: 4, // Enhanced elevation for Android
  },
  buttonText: {
    fontSize: 16,
    color: "#ffffff", // White text on buttons
    fontWeight: "600", // Slightly bolder
    textAlign: "center",
  },
  avatar: {
    marginBottom: 25,
    borderWidth: 3,
    borderColor: "#eeeeee", // Light grey border
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: "#4a4a4a",
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%', // Align buttons in a row with spacing
    marginTop: 10,
  },
  saveButton: {
    backgroundColor: '#5cb85c', // Green for "Save Changes"
    flex: 1, // Allow the button to expand to fill available space
    marginRight: 10, // Adds spacing between the buttons
  },
  cancelButton: {
    backgroundColor: '#d9534f', // Red for "Cancel"
    flex: 1, // Allow the button to expand to fill available space
  },
  uploadButton: {
    backgroundColor: "#4361ee",
    marginBottom: 20,
  },
});


 

export default Profile;
