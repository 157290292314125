
// 3 navigation. 
import Profilex from "../screens/Profilex";

import { UserProvider } from './UserContext';

import { MaterialIcons } from '@expo/vector-icons';



import { View, Text, TouchableOpacity, SafeAreaView } from "react-native";
import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Avatar } from "react-native-elements";
import { doc, getDoc } from "firebase/firestore";
import { database, auth } from "../componenets/firebase";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import CustomNavigationDrawer from "../componenets/CustomNavigationDrawer";
//import { DrawerContentScrollView, DrawerItemList, } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import Ionicons from "react-native-vector-icons/Ionicons";

// Screens imports:
import MyGames from "../screens/MyGames";
import TheGame from "../screens/TheGame";
import Players from "../screens/Players";
import Results from "../screens/Results";
import MakeGame from "../screens/MakeGame";
import MakeDates from "../screens/MakeDates";
// import ResaultScreen from "../screens/ResaultScreen"; // Taka út!!!
import ScoreCard from "../screens/ScoreCard";
// import Testleaderboard from "../screens/Testleaderboard";
import LeaderBoard from "../screens/LeaderBoard";
//import LeaderBoardTest from "../screens/LeaderBoardTest";
import AddPlayerScreen from "../screens/AddPlayerScreen";
import GameSettings from "../screens/GameSettings";
import Sandbox from "../screens/Sandbox";
import About from "../screens/About";
import GameDashboard from "../screens/GameDashboard";
import BeerCountLeaderboard from "../screens/BeerCountLeaderboard";
import LeagueTable from "../screens/LeagueTable";
import TabLeaderBoard from "../screens/TabLeaderBoard";
import BeerCounter from "../screens/BeerCounter";
import Pvp from "../screens/Pvp";
import PvpLeaderBoard from "../screens/PvpLeaderBoard";
import PvpLeaderScore from "../screens/PvpLeaderScore";
import KnockOut from "../screens/KnockOut";
import KnockOutResults from "../screens/KnockOutResults";
import Chat from "../screens/Chat";
import Bug from "../screens/Bug";
import Users from "../screens/UserList";
import GameList from "../screens/GameList";
import AccountSettings from "../screens/AccountSettings";
import PlayersInfo from "../screens/PlayersInfo";
//import DeleteConfirm from "../screens/DeleteConfirm";

//import DeleteAccount from "../screens/Deleteaccount";

import { getAuth, onAuthStateChanged } from "firebase/auth";






//import Individual from "../screens/Individual";

//import PlayQuizScreen from "../screens/PlayQuizScreen";

const Stack = createNativeStackNavigator();

const HeaderRight = ({ navigation }) => {
  const [userName, setUserName] = useState("");

  async function getUserInfo(userId) {
    const userRef = doc(database, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      //console.log("User data:", userSnapshot.data());
      setUserName(userSnapshot.data().name); // Update the user name
    } else {
      //console.log("No such user found!");
    }
  }

  useEffect(() => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userId = currentUser.uid;
      getUserInfo(userId);
    } else {
      //console.log("No user is signed in");
    }
  }, []);

  async function getUserInfo(userId) {
    const userRef = doc(database, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
     // console.log("User data:", userSnapshot.data());
      setUserName(userSnapshot.data().name); // Update the user name
      setAvatarUri(userSnapshot.data().avatarUri); // Update the avatar URI
    } else {
     // console.log("No such user found!");
    }
  }

  const [avatarUri, setAvatarUri] = useState("");

  return (
    <View style={{ marginLeft: 10 }}>
      <Avatar
        rounded
        size="medium"
        source={{          uri: avatarUri || "https://i.pravatar.cc/301",         }}
        onPress={() => navigation.openDrawer()}
        activeOpacity={0.1}
      />
      <Text style={{ color: "white", alignSelf: "flex-end", paddingRight: 10 }}>
        {userName || "YOU"}
      </Text>
    </View>
  );
};

const StackNavigation = () => (
  <Stack.Navigator
    screenOptions={({ navigation }) => ({
      headerStyle: { backgroundColor: "#4361ee" },
      headerTintColor: "white",
      headerTitleStyle: { fontWeight: "bold" },
      headerTitleAlign: "center",
      headerBackTitleVisible: false,
      headerBackTitle: "Back",
      headerBackTitleStyle: { color: "white" },
      headerRight: () => <HeaderRight navigation={navigation} />,
    })}
  >
    <Stack.Screen
      name="My Games"
      component={MyGames}
      options={{ headerShown: true, headerBackVisible: false }}
    />

    <Stack.Screen
      name="Game Date"
      component={TheGame}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="MakeDates"
      component={MakeDates}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Make Game"
      component={MakeGame}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="BeerCounter"
      component={BeerCounter}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="Pvp"
      component={Pvp}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="PvpLeaderBoard"
      component={PvpLeaderBoard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="PvpLeaderScore"
      component={PvpLeaderScore}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="KnockOut"
      component={KnockOut}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="KnockOutResults"
      component={KnockOutResults}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Chat"
      component={Chat}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    /> 

    <Stack.Screen
      name="AddPlayerScreen"
      component={AddPlayerScreen}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="GameSettings"
      component={GameSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="Players"
      component={Players}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Results"
      component={Results}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="LeaderBoard"
      component={LeaderBoard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="GameDashboard"
      component={GameDashboard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="BeerCountLeaderboard"
      component={BeerCountLeaderboard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="LeagueTable"
      component={LeagueTable}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="ScoreCard"
      component={ScoreCard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="AccountSettings"
      component={AccountSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen      
      name="PlayersInfo"
      component={PlayersInfo}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

  </Stack.Navigator>
);

function AnotherScreen1() {
  const navigation = useNavigation();

  return (
    <View>
      <Text>Another Navigation! </Text>
      <TouchableOpacity onPress={() => navigation.navigate("Profile")}>
        <Text>Drawer - Go to Profile </Text>
      </TouchableOpacity>
    </View>
  );
}

function DrawerProfile() {
  const navigation = useNavigation();

  return (
    <SafeAreaView>
      <View>
        <Text>Profile screen ! </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Another")}>
          <Text>Drawer - Go to Another Screen </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const Drawer = createDrawerNavigator();

function DrawerNavigation() {
  return (
    <Drawer.Navigator
      screenOptions={{ headerShown: false }}
      drawerContent={(navProps) => <CustomNavigationDrawer {...navProps} />}
    >
      <Drawer.Screen
        name="DrawerNavigation"
        component={StackNavigation}
        options={{
          drawerItemStyle: {
            display: "none",
          },
        }}
      />

      <Drawer.Screen name="Profile" component={Profilex} />
      {/* <Drawer.Screen name="Profile" component={DrawerProfile} /> */}
      {/* <Drawer.Screen name="Logout" component={"Logout"} /> */}
    </Drawer.Navigator>
  );
}

const Tab = createBottomTabNavigator();



const TabNavigator = () => {
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUserEmail(user ? user.email : null);
    });
    return unsubscribe;
  }, []);

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
      headerShown: false,

        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          switch (route.name) {
            case 'My Games': iconName = 'fort'; break;
            case 'Profile': iconName = 'person'; break;
            case 'About': iconName = 'info'; break;
            case 'Bug': iconName = 'bug-report'; break;
            case 'Users': iconName = 'group'; break;
            case 'GameList': iconName = 'games'; break;

            default: iconName = 'circle'; break;
          }
          return <MaterialIcons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: 'dodgerblue',
        tabBarInactiveTintColor: 'gray',
      })}
    >
      <Tab.Screen name="My Games" component={DrawerNavigation}/>
      <Tab.Screen name="Profile" component={Profilex} />
      <Tab.Screen name="About" component={About} />
      <Tab.Screen name="Bug" component={Bug} />
      { (userEmail === "dondanni@hotmail.com" || userEmail === "pathfix@test.com") && (  <Tab.Screen name="Users" component={Users} />)}
      {userEmail === "dondanni@hotmail.com" && <Tab.Screen name="GameList" component={GameList} />}
    </Tab.Navigator>
  );
};

export default function Navigation({ navigationRef, routeNameRef, onStateChange }) {
  
  // IMPORTANT: The NavigationContainer must be at the top of the component tree to work properly.
  // NavigationContainer NEEDS TO NOT RERENDER TO OFTEN ELSE BROWSER WILL MAKE ERROR ON TOO MANY HISTORY API CALLS.
  // Put any hooks in NavigationContents so this paranet does't reredner.

  // const user = {
  //   name: "John Doe", // Placeholder user information
  //   // Add other user properties as needed
  // };

  return (
    <UserProvider>
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        }}
        onStateChange={onStateChange}
      >
        <TabNavigator />
      </NavigationContainer>
    </UserProvider>
  );
}