import React, { useState, useEffect } from "react";
import { View, Text, Pressable, StyleSheet,Platform } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { setDoc, doc, onSnapshot, collection, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { database } from "../componenets/firebase";
import * as Players from "./Players";

export default function ResultsButton({
  data,
  onSelect,
  mainGame,
  TheGameId,
  playerId,
  scoreHome,
  scoreAway,
  name,
  email,
}) {
  const [userOption, setUserOption] = useState(3);
  const [result, setResult] = useState([]);

  useEffect(() => {
    const colRef = collection(database, "MainGame", mainGame, "TheGame", TheGameId, "Players");
    const q = query(colRef, where("id", "==", playerId));
    onSnapshot(q, (querySnapshot) => {
      let test = [];
      querySnapshot.docs.forEach((doc) => {
        test.push({ ...doc.data(), id: doc.id });
      });
      setResult(test);
    });
  }, []);

  const selectHandler = (value) => {
    onSelect(value);
    setUserOption(value);
  };

  const SaveRadio = async (item) => {
    const docRef = doc(
      database,
      "MainGame",
      mainGame,
      "TheGame",
      TheGameId,
      "Players",
      playerId
    );
  
    const updatedData = {
      //NewTest: "testing62",
      team: item.value,
      ResultButtonId: item.id,
      scoreHome: item.id === 1 ? +scoreHome : null,
      scoreAway: item.id === 2 ? +scoreAway : null,
    };
  
    await setDoc(docRef, updatedData, { merge: true });
  };
  
  

  return (
    <View>
      <View style={styles.radioGroup}>
        {data.map((item, index) => (
          <Pressable
            key={index}
            style={[
              styles.radioImage,
              result[0]?.ResultButtonId === item.id && { backgroundColor: item.radiobg },
            ]}
            onPress={() => {
  selectHandler(item.id);
  SaveRadio(item, scoreHome, scoreAway); // Pass the scoreHome and scoreAway values
}}
          >
            <FontAwesome
              name={result[0]?.ResultButtonId === item.id ? item.icon : "circle-o"}
              size={10}
              color={result[0]?.ResultButtonId === item.id ? item.bg : "#000"}
            />
            <Text style={styles.radioText}>{item.value}</Text>
          </Pressable>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  radioGroup: {
    flexDirection: "row",
  },
  radioImage: {
    alignItems: "center",
    justifyContent: "center",
    width: Platform.OS === 'web' ? 120 : 80,
    height: Platform.OS === 'web' ? 50 : 30,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "blue",
    marginHorizontal: 2,
  },
  radioText: {
    fontSize: Platform.OS === 'web' ? 15 : 10,
  },
});

