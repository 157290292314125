import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity
} from 'react-native';
import { getDoc, doc, updateDoc, getFirestore, onSnapshot } from 'firebase/firestore';

const PlayersInfo = ({ route }) => {
  const [players, setPlayers] = useState([]);
  const database = getFirestore();
  const currentMainGameId = route.params.mainGameId.toString();

  useEffect(() => {
    const docRef = doc(database, 'MainGame', currentMainGameId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setPlayers(docSnap.data().memberOfGame || []);
      }
    });
    return () => unsubscribe();
  }, [currentMainGameId]);

  const handleFollow = async (playerUid) => {
    const updatedPlayers = players.map(player =>
      player.uid === playerUid ? { ...player, isFollowing: !player.isFollowing } : player
    );

    setPlayers(updatedPlayers);
    const docRef = doc(database, 'MainGame', currentMainGameId);
    await updateDoc(docRef, {
      memberOfGame: updatedPlayers,
    });
  };

  const renderPlayerItem = ({ item }) => (
    <View style={styles.playerItem}>
      <Text style={styles.nameCell}>{item.name}</Text>
      <Text style={styles.emailCell}>{item.email}</Text>
      <TouchableOpacity style={styles.followButton} onPress={() => handleFollow(item.uid)}>
        <Text>{item.isFollowing ? 'Unfollow' : 'Follow - Not Ready!'}</Text>
      </TouchableOpacity>
    </View>
  );

  return (
    

    <View style={styles.container}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Players</Text>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}> Not ready yet! In beta testing </Text>

      <View style={styles.listContainer}>

        <FlatList
          data={players}
          renderItem={renderPlayerItem}
          keyExtractor={(item) => item.uid.toString()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#FFFFFF',
  },
  playerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: 'gray',
  },
  nameCell: {
    minWidth: 150,
    alignSelf: 'center',
  },
  emailCell: {
    flex: 2,
    margin: 5,
  },
  followButton: {
    marginLeft: 5,
    borderRadius: 5,
    backgroundColor: '#ADD8E6',
    width: 70,
    alignItems: 'center',
    textAlign: 'center',
  },
  listContainer: {
    flex: 1,
  },
});

export default PlayersInfo;
